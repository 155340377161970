@mixin text-input {
  height: 50px;
  width: 360px;
  color: #999999;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
}
.placeholder-color-change {
  input::-webkit-input-placeholder {
    color: #595959 !important;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #595959 !important;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #595959 !important;
  }

  input:-ms-input-placeholder {
    color: #595959 !important;
  }
}
