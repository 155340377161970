.active_order__appointment {
  color: #0099ff;
  font-size: 16px;
  margin-top: 5px;
  padding: 5px 20px;
  font-weight: normal;
}

.active_order__dashboard_column {
  margin-right: 0px;
}

.active_order__column {
  font-size: 14px;
  color: #999999;
  background-color: #f7f7f7 !important;
}

.vehicle-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 5px;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #dddddd;
}

.vehicle-container:hover {
  background-color: #f7f7f7;
}

.vehicledetail-recall-icon {
  padding-top: 12px;
  padding-left: 20px;
}

.vehicledetail-recall-schedule {
  flex: 1;
  text-align: right;
  padding-top: 8px;
  padding-right: 20px;
}

.vehicledetail-recall-schedule a {
  display: inline-block;
}

.vehicledetail-recall-service {
  font-size: 14px;
  padding: 13px 15px 15px 15px;
}

.vehicle_form__date_field_container div:first-of-type {
  flex: 4;
}

.vehicle_form__field {
  height: 60px;
  color: #999999;
  font-size: 18px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  flex: 4;
}

.vehicle_form__field_container {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
}

.vehicle_form__field_label {
  flex: 1;
  font-size: 18px;
  padding: 20px;
}

.vehicle-info {
  flex: 2;
  font-weight: bold;
}

.vehicles-item {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 15px;
  flex-direction: column;
  font-weight: bold;
  text-align: left;
}

.vehicles-item span {
  display: inline-block;
  color: #999999;
  font-size: 12px;
  font-weight: normal;
}
