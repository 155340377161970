@import "variables";

/* ***** Glossary colors: ***** */
$shell-yellow: #fbce07;
$shell-yellow-hover: #efc30b;
$shell-red: #dd1d21;
$shell-red-hover: #a51518;
$shell-dark-blue: #003c88;
$shell-dark-blue-hover: #3363a0;
$shell-gray: #595959;
/* **************************** */

$primary-color: $shell-yellow;
$primary-color-hover: $shell-yellow-hover;
$secondary-color: $shell-red;

$primary-button-color: $shell-dark-blue;
$primary-button-hover-color: $shell-dark-blue-hover;

/* Sign in screen background gradient */
$primary-color-gradient-start: $shell-yellow;
$primary-color-gradient-end: $shell-yellow;

/* Recharts */
$recharts-primary-color: $shell-red;
$recharts-secondary-color: $shell-red;
/* $recharts-neutral-color: no override */
$recharts-negative-color: black;

/* Sidebar icons */
$icon-color-active: $shell-red;
$icon-color-inactive: $shell-gray;

$font-family: Futura, Arial, sans-serif !important;
$font-color: $shell-gray;
$header-text-color: $shell-red;
$past-due-color: $shell-red;
$due-soon-color: $shell-dark-blue;

/* This is used in many places- not worth renaming the variable name for now */
$light-blue: $shell-dark-blue;
