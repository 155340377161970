.checkbox-signupbutton {
  margin-top: 17px;
  margin-bottom: 30px;
  width: 47%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (max-width: 325px) {
  .checkbox-signupbutton {
    width: 90%;
  }
}
