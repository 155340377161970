.no-session-container,
.no_session_layout__container {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
}

.main-logo {
  padding-top: 75px;
  svg {
    height: 385px;
    width: 385px;
  }
}
.fleetamerica-logo {
  padding-top: 30px;

  svg {
    height: 230px;
    width: 385px;
  }
}
.shell-logo {
  padding-top: 85px;
  min-width: 93%;
}
@media screen and (max-width: 400px) {
  .shell-logo {
    padding-top: 85px;
    width: 350px;
    height: auto;
  }
}
@media (min-width: 402px) and (max-width: 767px) {
  .shell-logo {
    padding-top: 85px;
    width: 400px;
    height: auto;
  }
}

.no-session-content,
.no_session_layout__content {
  flex: 1;
  display: flex;
  background-color: $no-session-content-block-color;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left-aligned-form-container {
  flex: 1;
  display: flex;
  background-color: $no-session-content-block-color;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;

  .left-aligned-form {
    .input-field-label {
      padding-bottom: 2px;
      font-size: 11px;
    }

    .left-aligned-field {
      margin-bottom: 15px;
      width: 100%;
    }

    .left-aligned-submit {
      margin-top: 40px;
    }

    .left-align-privacy-terms {
      margin-top: 30px;
    }
  }
}

.no-session-logo,
.no_session_layout__logo,
.no-session-logo-no-tagline {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 40vh;
  height: auto !important;
}

.fleetadvise-logo {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 22vh;
  height: auto !important;
}

.no-session-cta,
.no_session_layout__cta {
  flex: 1;
}

button.ui.button.button--submit {
  margin-right: 3em;
}

// Largest Mobile Screen- up to Tablet
@media screen and (max-width: 768px) {
  .no-session-container {
    display: block !important;
    background-color: $no-session-content-block-color;
    width: auto;
    height: 100%;
    svg {
      height: 210px;
      width: 210px;
    }
  }

  .no-session-content {
    margin-top: 3em;
    div {
      margin: 0.75em !important;
    }
  }

  .no-session-logo,
  .no_session_layout__logo {
    display: block !important;
    height: 22em;
  }

  .sign-in-form {
    display: flex-flow;
    height: auto;
  }

  .left-aligned-form-container {
    .left-aligned-form {
      margin-top: 40px;
      width: 50%;

      .left-aligned-submit {
        margin-top: 30px;
      }
    }
  }

  div.sign-in-submit-no-margin {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  div.sign-in-submit {
    margin-left: 2em !important;
    margin-right: 1.5em !important;
    padding-bottom: 0.2em;
  }
  button.link {
    margin-left: 2em;
  }

  .no-session-logo-tag-line {
    padding-left: 25%;
    margin-top: 1%;
  }
}

@media screen and (max-width: 425px) {
  .left-aligned-form-container {
    .left-aligned-form {
      width: 80%;

      .left-aligned-submit {
        margin-top: 20px;
      }

      .left-align-privacy-terms {
        margin-top: 10px;
      }
    }
  }
}

// Smaller iPhones
@media screen and (max-width: 350px) {
  .no-session-logo-tag-line {
    padding-left: 20%;
    margin-top: 5%;
    font-size: 1.2em;
  }

  .sign-in-input input {
    margin-left: 2.5em !important;
    margin-right: 2.5em !important;
  }
}

.no-session-header-signup,
.no_session_layout__header {
  @include horizontal-primary-gradient;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  padding-left: 3.5%;
  padding-right: 3.5%;
}
@media screen and (max-width: 768px) {
  .no-session-header-signup {
    padding-left: 8%;
    padding-right: 8%;
  }
}

.no-session-header-signup p,
.no-session-header-signin p {
  color: white;
  margin-bottom: 0;
  text-align: left; /* For Edge */
  -moz-text-align-last: left; /* For Firefox prior 58.0 */
  text-align-last: left;
}

.no-session-header-signin {
  @include horizontal-primary-gradient;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.display-nothing {
  display: none;
}
.no-session-header-displayFleetInfo {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 800px) {
  .no-session-header-displayFleetInfo {
    margin-bottom: 60px;
  }
}
.display-fleet-design {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.short-info-signin-page {
  color: white;
  font-size: 18.5px;
  margin-top: 15px;
}

.display-shortinfo-text-container {
  margin-top: 12px;
}
.display-visit-text-container {
  margin-top: 130px;
}
.display-visit-fleetadvise-info {
  color: white;
  font-size: 15px;
}
@media screen and (max-width: 330px) {
  .short-info-signin-page {
    margin-left: 50px;
  }
}
@media screen and (max-width: 500px) {
  .short-info-signin-page {
    margin-left: 75px;
  }
}

.display-nothing {
  display: none;
}
.short-info {
  color: white;
  font-size: 18.5px;
  margin-bottom: 50px;
}
@media screen and (max-width: 376px) {
  .short-info {
    margin-top: 20px;
    margin-left: 60px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 325px) {
  .short-info {
    margin-bottom: 40px;
  }
}

.fleet-info-container {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  min-height: 85px;
}
.fleet-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fleet-info-image-container {
  border-radius: 50%;
  margin-right: 15px;
  max-height: 76px;
  min-width: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 46px;
    width: 45px;
  }
}
.fleet-info-text-header {
  font-size: 22px;
}
.fleet-info-text {
  font-size: 17px;
}

.no-session-logo-tag-line {
  position: absolute;
  font-family: Helvetica;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 7.33px;
  text-align: center;
  width: 50%;
  padding-top: 70px;
}

html,
body {
  width: 100vw;
  height: 100vh;
}
