.payment-row {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 5px;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #dddddd;
}

.payment-column {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 15px;
  flex-direction: column;
  font-weight: bold;
}

.payment-column span {
  display: inline-block;
  color: #999999;
  font-size: 12px;
  font-weight: normal;
}
