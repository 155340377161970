.select {
  height: 60px;
  width: 100%;
  color: #999999;
  font-size: 18px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}

.select--small {
  height: 45px;
  width: 100%;
  color: #999999;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}
