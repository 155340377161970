.privacy_policy h1 {
  font-weight: bold;
  color: black;
  font-size: 50px;
  text-align: center;
}
.privacy_policy h2 {
  font-weight: bold;
  color: black;
  text-align: center;
  text-transform: uppercase;
}
.privacy_policy p {
  font-size: 18px;
  color: black !important;
}
.privacy_policy ul {
  list-style-type: disc;
}
.privacy_policy li {
  font-size: 18px;
  margin-bottom: 14px;
  word-spacing: 2.3px;
}

.privacy_policy.content {
  font-family: Arial;
  margin: auto;
  width: 75%;
  padding: 45px;
  background: white;
  color: black;
}
.privacy_policy .Contactus {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
