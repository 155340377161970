button:disabled {
  opacity: 0.6;
}

.button--add {
  font-size: 14px;
  font-weight: bold;
  color: #0099ff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 6px;
  background: linear-gradient(#ffffff, #efefef);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.button--alternate {
  margin-bottom: 20px;
  width: 100px;
  font-size: 11px;
  font-weight: bold;
  color: #f8991d;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 6px;
  background: linear-gradient(#ffffff, #efefef);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.button--primary {
  margin-bottom: 20px;
  width: 100px;
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 6px;
  background: linear-gradient($button-gradient-start-color, $button-gradient-end-color);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.button--schedule {
  font-size: 14px;
  font-weight: bold;
  color: #f8991d;
  padding: 10px 20px;
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  background: linear-gradient(#ffffff, #efefef);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.button--schedule-small {
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  color: #f8991d;
  padding: 5px 10px;
  margin-top: 5px;
  text-decoration: none;
  text-align: center;
  border-radius: 6px;
  background: linear-gradient(#ffffff, #efefef);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.button--submit {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  padding: 20px 40px;
  max-width: 200px;
  text-decoration: none;
  border-radius: 6px;
  background: linear-gradient($button-gradient-start-color, $button-gradient-end-color);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.button--thin {
  margin-bottom: 0;
  padding: 5px 10px;
}

.button--view-all {
  font-size: 14px;
  font-weight: bold;
  color: #999;
  padding: 5px 20px;
  text-decoration: none;
  border-radius: 6px;
  background: linear-gradient(#fff, #efefef);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.submit_button--alternate {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #f8991d;
  padding: 20px 150px;
  text-decoration: none;
  border-radius: 6px;
  background: linear-gradient(#ffffff, #efefef);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.submit_button--primary {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  padding: 20px 150px;
  text-decoration: none;
  border-radius: 6px;
  background: linear-gradient($button-gradient-start-color, $button-gradient-end-color);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

button.link {
  background: none !important;
  border: none;
  padding: 0 !important;

  color: #069;
  font-size: larger;
  cursor: pointer;
}
button.link:hover {
  text-decoration: underline;
}

.ui.button:focus {
  background-color: #3363a0 !important;
  color: #fff !important;
}
